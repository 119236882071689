@import './../../../../scss/theme-bootstrap';

.video-block {
  position: relative;
  &__tout {
    position: relative;
    .basic-carousel & {
      @include breakpoint($medium-up) {
        padding: 20px 60px 0;
      }
    }
    &-headline {
      @include body-large;
      @include breakpoint($small-down) {
        font-size: 18px;
      }
      .basic-carousel & {
        @include eyebrow-small;
        @include breakpoint($small-down) {
          font-size: 18px;
        }
        p {
          margin-bottom: 10px;
        }
      }
    }
    &-subhead {
      @include body-large;
      .basic-carousel & {
        @include body-small;
        @include breakpoint($medium-up) {
          padding-left: 35px;
          padding-right: 35px;
        }
        p {
          margin-bottom: 10px;
        }
      }
    }
  }
}
